import React from 'react';
// import Btn from '../Button';
import { Modal, ModalBody, 
  // ModalFooter, 
  ModalHeader } from 'reactstrap';
// import { Close, SaveChanges } from 'Utils/Constant';

const CommonModal = (props) => {
  return (
    <Modal isOpen={props.isOpen} onClosed={props.isClose} toggle={props.toggler} size={props.size} centered>
      <ModalHeader toggle={props.toggler}>
        {props.title}
      </ModalHeader>
      <ModalBody className={props.bodyClass}>
        {props.children}
      </ModalBody>
      {/* <ModalFooter>
        <Btn attrBtn={{ color: 'secondary', onClick: props.toggler }} >{Close}</Btn>
        <Btn attrBtn={{ color: 'primary', onClick: props.toggler }}>{SaveChanges}</Btn>
      </ModalFooter> */}
    </Modal>
  );
};

export default CommonModal;