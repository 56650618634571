import { Loader } from 'Core/Components';
import AppLayout from 'Core/Layout/Layout';
import React, { lazy, Suspense } from 'react';
import { Route, Routes, Navigate } from 'react-router-dom';
import auth from '../Utils/Helper/AuthHelpers';
// ADMIN PAGES
const UserManagerPage = lazy(() => import('Views/Admin/UserManager/UsersPage'));
const ProductPage = lazy(() => import('Views/Admin/ManageProduct/ProductPage'));
const ConfigureManagerPage = lazy(() => import('Views/Admin/ConfigureManager/ConfigureManagerPage'));
// USER PAGES
const ReportPage = lazy(() => import('Views/Partner/Report/ReportPage'));
const PartnerProductPage = lazy(() => import('Views/Partner/ManageProduct/ProductPage'));
const SubscriptionPage = lazy(() => import('Views/Partner/Subscription/SubscriptionPage'));
const AccountPage = lazy(() => import('Views/Account'));

const PrivateRoutes = () => {
  const userInfo = auth.getUserInfo();
  
  // Function to check if the user type has access to a specific route
  const hasAccess = (routeUserType) => {
    return userInfo?.user_type === routeUserType;
  };
  
  return (
    <Routes>
      <Route element={<AppLayout />}>
        {/* Lazy Modules */}
        {hasAccess('USER') && (
          <>
            {/* Redirect to Dashboard after success login/registartion */}
            <Route path='auth/*' element={<Navigate to='/dashboard/summarize-report' />} />
            <Route 
              path='dashboard/*' 
              element={
                <SuspensedView>
                  <ReportPage />
                </SuspensedView>
              } 
            />
            <Route 
              path='products/*' 
              element={
                <SuspensedView>
                  <PartnerProductPage />
                </SuspensedView>
              } 
            />
            <Route 
              path='subscription/*' 
              element={
                <SuspensedView>
                  <SubscriptionPage />
                </SuspensedView>
              } 
            />
          </>
        )}

        {hasAccess('ADMIN') && (
          <>
            {/* Redirect to Dashboard after success login/registartion */}
            <Route path='auth/*' element={<Navigate to='/dashboard/user-list' />} />
            <Route 
              path='dashboard/*' 
              element={
                <SuspensedView>
                  <UserManagerPage />
                </SuspensedView>
              } 
            /> 
            <Route 
              path='products/*' 
              element={
                <SuspensedView>
                  <ProductPage />
                </SuspensedView>
              } 
            />
            <Route 
              path='configure/*' 
              element={
                <SuspensedView>
                  <ConfigureManagerPage />
                </SuspensedView>
              } 
            />
          </>
        )}

        <Route 
          path='account' 
          element={
            <SuspensedView>
              <AccountPage />
            </SuspensedView>
          } 
        />
        {/* Unauthorized Route */}
        {/* <Route path="unauthorized" element={<UnauthorizedPage />} /> */}
        {/* Page Not Found */}
        <Route path='*' element={<Navigate to='/error/404' />} />
      </Route>
    </Routes>
  )
};

const SuspensedView = ({children}) => {
  return <Suspense fallback={<Loader />}>{children}</Suspense>
}

export default PrivateRoutes;
