import {Route, Routes, Outlet} from 'react-router-dom';
import Error404 from './Components/Error400';
import Error500 from './Components/Error500';

const ErrorsPage = () => (
  <Routes>
    <Route element={<Outlet />}>
      <Route path='404' element={<Error404 />} />
      <Route path='500' element={<Error500 />} />
      <Route index element={<Error404 />} />
    </Route>
  </Routes>
)

export default ErrorsPage;
