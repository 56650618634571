import React, { useEffect, useState } from 'react';
import { useNavigate } from 'react-router-dom';
import { useAuth } from 'Utils/Hooks/useAuth';
import { LogIn, User } from 'react-feather';
import man from 'assets/images/user.png';
import { LI, UL, Image, P } from 'Core/Components';
import { Account, LogOut } from 'Utils/Constant';

const UserHeader = () => {
  const history = useNavigate();
  const { currentUser } = useAuth();
  const [profile, setProfile] = useState('');
  const [name, setName] = useState('Emay Walter');
  const authenticated = JSON.parse(localStorage.getItem('authenticated'));
  const auth0_profile = JSON.parse(localStorage.getItem('auth0_profile'));

  useEffect(() => {
    setProfile(localStorage.getItem('profileURL') || man);
    setName(localStorage.getItem('Name') ? localStorage.getItem('Name') : name);
  }, []);

  const Logout = () => {
    history(`${process.env.PUBLIC_URL}/logout`);
  };

  const UserMenuRedirect = (redirect) => {
    history(redirect);
  };

  return (
    <li className='profile-nav onhover-dropdown pe-0 py-0'>
      <div className='media profile-media'>
        <Image
          attrImage={{
            className: 'b-r-10 m-0',
            src: `${authenticated ? auth0_profile.picture : profile}`,
            alt: '',
          }}
        />
        <div className='media-body'>
          <span>{currentUser && currentUser.email}</span>
          <P attrPara={{ className: 'mb-0 font-roboto' }}>
          {currentUser && currentUser.user_type} <i className='middle fa fa-angle-down'></i>
          </P>
        </div>
      </div>
      <UL attrUL={{ className: 'simple-list profile-dropdown onhover-show-div' }}>
        <LI
          attrLI={{
            onClick: () => UserMenuRedirect(`${process.env.PUBLIC_URL}/account`),
          }}>
          <User />
          <span>{Account} </span>
        </LI>
        <LI attrLI={{ onClick: Logout }}>
          <LogIn />
          <span>{LogOut}</span>
        </LI>
      </UL>
    </li>
  );
};

export default UserHeader;
