import * as yup from 'yup';
import { toast } from 'react-toastify';
import { Link } from 'react-router-dom';
import request from 'Utils/Helper/Request';
import { useAuth } from 'Utils/Hooks/useAuth';
import authHelper from 'Utils/Helper/AuthHelpers';
import React, { Fragment, useState } from 'react';
import { yupResolver } from '@hookform/resolvers/yup';
import { useForm, Controller } from 'react-hook-form';
import { Form, FormGroup, Input, Label, Row, Col, Container } from 'reactstrap';
import { Btn, H4, P } from 'Core/Components';
// import logoWhite from 'assets/images/logo/logo.png';
// import logoDark from 'assets/images/logo/logo_dark.png';

const RegisterFrom = ({ logoClassMain }) => {
  const {setCurrentUser} = useAuth();
  const [isLoading, setIsLoading] = useState(false);
  const [togglePassword, setTogglePassword] = useState(false);

  //** Default Values */
  const defaultValues = {
    name: '',
    email: '',
    phone: '',
    password: '',
    terms: false
  };

  // ** Register Schema
  const registerSchema = yup.object().shape({
    name: yup.string().required('Name is a required field'),
    email: yup.string().required('Email is a required field'),
    phone: yup.string().required('Phone is a required field'),
    password: yup.string().required('Password is a required field'),
    terms: yup.bool().oneOf([true], 'Please accept the terms and conditions')
  });
  
  /** Onsubmit */
  const {
    reset,
    control,
    handleSubmit,
    formState: { errors, isSubmitting },
  } = useForm({ defaultValues, resolver: yupResolver(registerSchema) });

  const onSubmit = (values) => {
    setIsLoading(true);
    request(`${process.env.REACT_APP_API_URL}/auth/register`, { 
      method: 'POST', 
      body: values
    })
    .then(async(response) => {
      reset();
      setIsLoading(false);
      authHelper.setToken(response.data.access_token);
      authHelper.setUserInfo(response.data.user);
      setCurrentUser(response.data.user);
      toast.success(response.message, {
        position: toast.POSITION.TOP_RIGHT,
      });
    })
    .catch((error) => {
      if (error.response.status > 200) {
        setIsLoading(false);
        toast.error(error.response.payload.message, {
          position: toast.POSITION.TOP_RIGHT,
        });
      }
    })
  };
  return (
    <Fragment>
      <section>
        <Container fluid={true} className='p-0'>
          <Row className='m-0'>
            <Col className='p-0'>
              <div className='login-card'>
                <div>
                  <div>
                    <Link className={`logo ${logoClassMain ? logoClassMain : ''}`} to={process.env.PUBLIC_URL} style={{fontWeight: 'bolder', fontSize: 40}}>
                      {/* <Image attrImage={{ className: 'img-fluid for-light', src: logoWhite, alt: 'looginpage' }} />
                      <Image attrImage={{ className: 'img-fluid for-dark', src: logoDark, alt: 'looginpage' }} /> */}
                      MSR
                    </Link>
                  </div>
                  <div className='login-main'>
                    <Form onSubmit={handleSubmit(onSubmit)} className='theme-form login-form'>
                      <H4>Create your account</H4>
                      <P>Enter your personal details to create account</P>
                      <FormGroup>
                        <Label className='col-form-label m-0 pt-0'>Your Name</Label>
                        <Controller
                          name="name"
                          control={control}
                          render={({ field, fieldState }) => (
                            <Input
                              type="text"
                              name={field.name}
                              id={field.name}
                              placeholder="Enter your name"
                              value={field.value}
                              onChange={field.onChange}
                              invalid={fieldState.invalid}
                            />
                          )}
                        />
                        {errors.name && <span className="text-danger">{errors.name.message}</span>}
                      </FormGroup>
                      <FormGroup>
                        <Label className='col-form-label m-0 pt-0'>Email Address</Label>
                        <Controller
                          name="email"
                          control={control}
                          rules={{ required: true, pattern: /^\S+@\S+$/i }}
                          render={({ field, fieldState }) => (
                            <Input
                              type="email"
                              name={field.name}
                              id={field.name}
                              placeholder="Enter your email"
                              value={field.value}
                              onChange={field.onChange}
                              invalid={fieldState.invalid}
                            />
                          )}
                        />
                        {errors.email && <span className="text-danger">{errors.email.message}</span>}
                      </FormGroup>
                      <FormGroup>
                        <Label className='col-form-label m-0 pt-0'>Phone Number</Label>
                        <Controller
                          name="phone"
                          control={control}
                          render={({ field, fieldState }) => (
                            <Input
                              type="text"
                              name={field.name}
                              id={field.name}
                              placeholder="Enter your phone number"
                              value={field.value}
                              onChange={field.onChange}
                              invalid={fieldState.invalid}
                            />
                          )}
                        />
                        {errors.phone && <span className="text-danger">{errors.phone.message}</span>}
                      </FormGroup>
                      <FormGroup className='position-relative'>
                        <Label className='col-form-label m-0 pt-0'>Password</Label>
                        <Controller
                          name="password"
                          control={control}
                          render={({ field, fieldState }) => (
                            <div className='position-relative'>
                              <Input 
                                type={togglePassword ? 'text' : 'password'} 
                                name={field.name}
                                id={field.name}
                                value={field.value} 
                                className='form-control' 
                                onChange={field.onChange}
                                placeholder='*********' 
                                invalid={fieldState.invalid}
                              />
                              <div className='show-hide' onClick={() => setTogglePassword(!togglePassword)}>
                                <span className={togglePassword ? '' : 'show'}></span>
                              </div>
                            </div>
                          )}
                        />
                        {errors.password && <span className="text-danger">{errors.password.message}</span>}
                      </FormGroup>
                      <FormGroup>
                        <Controller
                          name="terms"
                          control={control}
                          defaultValue={false}
                          rules={{ required: true }}
                          render={({ field: { value, onChange } }) => (
                            <Fragment>
                              <div className='checkbox'>
                                <Input 
                                  id='checkbox1' 
                                  type='checkbox' 
                                  checked={value}
                                  onChange={(e) => onChange(e.target.checked)}
                                  invalid={!!errors.terms}
                                />
                                <Label className='text-muted' for='checkbox1'>
                                  Agree with <span>Privacy Policy</span>
                                </Label>
                              </div>
                              {errors.terms && <span className="text-danger">{errors.terms.message}</span>}
                            </Fragment>
                          )}
                        />
                      </FormGroup>
                      <FormGroup>
                        <Btn attrBtn={{ className: 'd-block w-100', color: 'primary', type: 'submit' }}>
                          Create Account {(isSubmitting || isLoading) && <span className='spinner-border spinner-border-sm align-middle ms-2'></span>}
                        </Btn>
                      </FormGroup>
                      <P attrPara={{ className: 'mb-0 text-center' }}>
                        Already have an account?
                        <Link className='ms-2' to={`${process.env.PUBLIC_URL}/auth/login`}>
                          Sign in
                        </Link>
                      </P>
                    </Form>
                  </div>
                </div>
              </div>
            </Col>
          </Row>
        </Container>
      </section>
    </Fragment>
  );
};

export default RegisterFrom;
