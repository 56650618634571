import React from 'react';
import Routers from './Routes/AuthRoutes';
import { ToastContainer } from 'react-toastify';
import { AuthProvider, AuthInit } from 'Utils/Hooks/useAuth';
import AnimationThemeProvider from './Core/Helper/AnimationTheme/AnimationThemeProvider';
import CustomizerProvider from './Core/Helper/Customizer/CustomizerProvider';

const App = () => (
  <div className='App'>
    <CustomizerProvider>
      <AnimationThemeProvider>
        <AuthProvider>
          <AuthInit>
            <Routers />
            <ToastContainer />
          </AuthInit>
        </AuthProvider>
      </AnimationThemeProvider>                     
    </CustomizerProvider>
  </div>
);

export default App;
