import * as yup from 'yup';
import { toast } from 'react-toastify';
import { Link } from 'react-router-dom';
import request from 'Utils/Helper/Request';
import { useAuth } from 'Utils/Hooks/useAuth';
import React, { Fragment, useState } from 'react';
import authHelper from 'Utils/Helper/AuthHelpers';
import { useForm, Controller } from 'react-hook-form';
import { yupResolver } from '@hookform/resolvers/yup';
import { Btn, H4, P } from 'Core/Components';
import { Col, Container, Row, Form, FormGroup, Input, Label } from 'reactstrap';
// import logoWhite from 'assets/images/logo/logo.png';
// import logoDark from 'assets/images/logo/logo_dark.png';

const LoginForm = ({ logoClassMain }) => {
  const {setCurrentUser} = useAuth();
  const [isLoading, setIsLoading] = useState(false);
  const [togglePassword, setTogglePassword] = useState(false);

  //** Default Values */
  const defaultValues = {
    email: '',
    password: ''
  };

  // ** Login Schema
  const loginSchema = yup.object().shape({
    email: yup.string().required('Email is a required field'),
    password: yup.string().required('Passowrd is a required field')
  });
  
  /** Onsubmit */
  const {
    reset,
    control,
    handleSubmit,
    formState: { errors, isSubmitting },
  } = useForm({ defaultValues, resolver: yupResolver(loginSchema) });

  const onSubmit = (values) => {
    setIsLoading(true);
    request(`${process.env.REACT_APP_API_URL}/auth/login`, { 
      method: 'POST', 
      body: values
    })
    .then(async(response) => {
      if (response.status) {
        reset();
        setIsLoading(false);
        authHelper.setToken(response.data.access_token);
        authHelper.setUserInfo(response.data.user);
        setCurrentUser(response.data.user);
        toast.success(response.message, {
          position: toast.POSITION.TOP_RIGHT,
        });
      } else {
        setIsLoading(false);
        toast.error(response.message, {
          position: toast.POSITION.TOP_RIGHT,
        });
      }
    })
    .catch((error) => {
      if (error.response.status > 200) {
        setIsLoading(false);
        toast.error(error.response.payload.message, {
          position: toast.POSITION.TOP_RIGHT,
        });
      }
    })
  };
  return (
    <Fragment>
      <Container fluid={true} className="p-0">
        <Row>
          <Col>
            <div className='login-card'>
              <div>
                <div>
                  <Link className={`logo ${logoClassMain ? logoClassMain : ''}`} to={process.env.PUBLIC_URL} style={{fontWeight: 'bolder', fontSize: 40}}>
                    {/* <Image attrImage={{ className: 'img-fluid for-light', src: logoWhite, alt: 'looginpage' }} />
                    <Image attrImage={{ className: 'img-fluid for-dark', src: logoDark, alt: 'looginpage' }} /> */}
                    MSR
                  </Link>
                </div>
                <div className='login-main'>
                  <Form onSubmit={handleSubmit(onSubmit)} className='theme-form login-form'>
                    <H4>Sign in to account</H4>
                    <P>Enter your email & password to login</P>
                    <FormGroup>
                      <Label className='col-form-label m-0'>Email Address</Label>
                      <Controller
                        name="email"
                        control={control}
                        defaultValue=""
                        rules={{ required: true, pattern: /^\S+@\S+$/i }}
                        render={({ field, fieldState }) => (
                          <Input
                            type="email"
                            name={field.name}
                            id={field.name}
                            placeholder="Enter your email"
                            value={field.value}
                            onChange={field.onChange}
                            invalid={fieldState.invalid}
                          />
                        )}
                      />
                      {errors.email && <span className="text-danger">{errors.email.message}</span>}
                    </FormGroup>
                    <FormGroup className='position-relative'>
                      <Label className='col-form-label m-0'>Password</Label>
                      <Controller
                        name="password"
                        control={control}
                        render={({ field, fieldState }) => (
                          <div className='position-relative'>
                            <Input 
                              type={togglePassword ? 'text' : 'password'} 
                              name={field.name}
                              id={field.name}
                              value={field.value} 
                              className='form-control' 
                              onChange={field.onChange}
                              placeholder='*********' 
                              invalid={fieldState.invalid}
                            />
                            <div className='show-hide' onClick={() => setTogglePassword(!togglePassword)}>
                              <span className={togglePassword ? '' : 'show'}></span>
                            </div>
                          </div>
                        )}
                      />
                      {errors.password && <span className="text-danger">{errors.password.message}</span>}
                    </FormGroup>
                    <FormGroup className='position-relative'>
                      <div className='checkbox'>
                        <Input id='checkbox1' type='checkbox' />
                        <Label className='text-muted' for='checkbox1'>
                          Remember password
                        </Label>
                      </div>
                      {/* <Link className='link' to={`${process.env.PUBLIC_URL}/pages/authentication/forget-pwd`}>
                        Forgot password?
                      </Link> */}
                    </FormGroup>
                    <FormGroup>
                      <Btn attrBtn={{ disabled: isSubmitting || isLoading, className: 'd-block w-100 mt-2', color: 'primary', type: 'submit' }}>
                        Sign in {(isSubmitting || isLoading) && <span className='spinner-border spinner-border-sm align-middle ms-2'></span>}
                      </Btn>
                    </FormGroup>
                    <P attrPara={{ className: 'text-center mb-0 ' }}>
                      Don't have account?
                      <Link className='ms-2' to={`${process.env.PUBLIC_URL}/auth/register`}>
                        Create Account
                      </Link>
                    </P>
                  </Form>
                </div>
              </div>
            </div>
          </Col>
        </Row>
      </Container>
    </Fragment>
  );
};

export default LoginForm;
