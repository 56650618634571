export const MENUITEMS = [
  
  {
    menutitle: 'GENERAL',
    menucontent: 'Dashboard, Products, Subscription',
    menutype: 'User',
    Items: [
      {
        title: 'Dashboard',
        icon: 'home',
        type: 'sub',
        active: false,
        children: [
          { path: `${process.env.PUBLIC_URL}/dashboard/summarize-report`, title: 'Summarize-Report', type: 'link' },
          { path: `${process.env.PUBLIC_URL}/dashboard/report-by-product`, title: 'Report-by-Product', type: 'link' },
          { path: `${process.env.PUBLIC_URL}/dashboard/subscribers-list`, title: 'Subscribers-List', type: 'link' }
        ],
      },
      {
        title: 'Products',
        icon: 'widget',
        type: 'sub',
        active: false,
        children: [
          { path: `${process.env.PUBLIC_URL}/products/product-list`, title: 'Product-List', type: 'link' },
          { path: `${process.env.PUBLIC_URL}/products/send-message`, type: 'link', title: 'Send-Message' }
        ],
      },
      {
        title: 'Subscription',
        icon: 'project',
        type: 'sub',
        active: false,
        children: [
          { path: `${process.env.PUBLIC_URL}/subscription/initiate`, title: 'Initiate', type: 'link' },
          { path: `${process.env.PUBLIC_URL}/subscription/unsubscribe`, title: 'Unsubscribe', type: 'link' }
        ],
      },
    ],
  },

  {
    menutitle: 'GENERAL',
    menucontent: 'User, Configure ShortCode, Product Manager',
    menutype: 'Admin',
    Items: [
      {
        title: 'User Manager',
        icon: 'user',
        type: 'sub',
        active: false,
        children: [
          { path: `${process.env.PUBLIC_URL}/dashboard/user-list`, type: 'link', title: 'User-List' }
        ],
      },
      {
        title: 'Configure ShortCode',
        icon: 'table',
        type: 'sub',
        children: [
          { path: `${process.env.PUBLIC_URL}/configure/add-sms-shortcode`, title: 'Add-SMS-Shortcode', type: 'link', },
          { path: `${process.env.PUBLIC_URL}/configure/modify-ussd-shortcode`, title: 'Modify-USSD-Shortcode', type: 'link', },
          { path: `${process.env.PUBLIC_URL}/configure/configure-mtn`, title: 'Configure-MTN', type: 'link', },
        ],
      },
      {
        title: 'Product Manager',
        icon: 'editors',
        type: 'sub',
        active: false,
        children: [
          { path: `${process.env.PUBLIC_URL}/products/product-list`, type: 'link', title: 'Product-List' },
          { path: `${process.env.PUBLIC_URL}/products/create-product`, type: 'link', title: 'Create-Product' }
        ],
      },
    ],
  }
];
