import {Route, Routes, Outlet, Navigate} from 'react-router-dom';
import Login from './Components/LoginForm';
import Register from './Components/RegisterForm';
import ForgotPassword from './Components/ForgotPassword';
import ResetPassword from './Components/CreatePwd';
import UnlockUser from './Components/UnlockUser';

const AuthPage = () => (
  <Routes>
    <Route element={<Outlet />}>
      <Route path='login' element={<Login />} />
      <Route path='register' element={<Register />} />
      <Route path='forgot' element={<ForgotPassword />} />
      <Route path='reset/:email' element={<ResetPassword />} />
      <Route path='unlock' element={<UnlockUser />} />
      <Route index element={<Login />} />
      <Route path='*' element={<Navigate to='/error/404' />} />
    </Route>
  </Routes>
)

export default AuthPage;
